/* ---------------------
	*** Utils ***
-----------------------*/


@import "utils/variables";

:root {
    --theme-default: #e29107;;
    --theme-secondary: #dc3545;
}

/* ---------------------
	*** Icons Scss ***
-----------------------*/
@import "vendors/whether-icon";
@import "vendors/flag-icon";
@import "vendors/icofont";
@import "vendors/themify";

/* ---------------------
	*** Base ***
-----------------------*/

@import "base/reset";
@import "base/typography";


/* ---------------------
	*** Components ***
-----------------------*/

@import "components/according.scss";
@import "components/alert.scss";
@import "components/avatars.scss";
@import "components/badge.scss";
@import "components/bookmark.scss";
@import "components/breadcrumb.scss";
@import "components/buttons.scss";
@import "components/card.scss";
@import "components/dropdown.scss";
@import "components/form-input.scss";
@import "components/form-wizard.scss";
@import "components/forms.scss";
@import "components/icons.scss";
@import "components/list.scss";
@import "components/loader.scss";
@import "components/modal.scss";
@import "components/popover.scss";
@import "components/print.scss";
@import "components/radio.scss";
@import "components/ribbon.scss";
@import "components/switch.scss";
@import "components/tab.scss";
@import "components/table.scss";
@import "components/touchspin.scss";

@import "components/angular-plugin.scss";
@import "components/calendar.scss";
@import "components/ag-grid.scss";
@import "components/aw-wizard.scss";
@import "components/datatable.scss";
@import "components/datepicker.scss";

@import "components/typeahead-search";
@import "components/rating";


/* ---------------------
	*** Pages ***
-----------------------*/

@import "pages/blog.scss";
@import "pages/bookmark-app.scss";
@import "pages/cart.scss";
@import "pages/chart.scss";
@import "pages/chat.scss";
@import "pages/checkout.scss";
@import "pages/comingsoon.scss";
@import "pages/contacts.scss";
@import "pages/dashboard_2.scss";
@import "pages/dashboard_default.scss";
@import "pages/ecommerce.scss";
@import "pages/email-application.scss";
@import "pages/errorpage.scss";
@import "pages/faq.scss";
@import "pages/file.scss";
@import "pages/gallery.scss";
@import "pages/job-search.scss";
@import "pages/knowledgebase.scss";
@import "pages/language.scss";
@import "pages/learning.scss";
@import "pages/login.scss";
@import "pages/megaoption.scss";
@import "pages/order-history.scss";
@import "pages/page.scss";
@import "pages/pricing.scss";
@import "pages/progress.scss";
@import "pages/projectlist.scss";
@import "pages/social-app.scss";
@import "pages/task.scss";
@import "pages/user-profile.scss";
@import "pages/wishlist.scss";


/* ---------------------
	*** Themes ***
-----------------------*/

@import "themes/dark.scss";
@import "themes/dark-sidebar.scss";
@import "themes/theme-customizer.scss";


/* ---------------------
	*** Layout ***
-----------------------*/

@import "layout/footer.scss";
@import "layout/grid.scss";
@import "layout/header.scss";
@import "layout/navs.scss";
@import "layout/search.scss";
@import "layout/select2.scss";
@import "layout/sidebar.scss";
@import "layout/rtl.scss";
@import "layout/box-layout.scss";

/* ---------------------
	*** Website Theme css ***
-----------------------*/
@import "vendors/mag-popup.scss";
@import "vendors/swiper.scss";
//@import "website.scss";
//@import "~../src/assets/web.css";

// .website p {
// 	font-size: unset;
// 	font-family: unset;
// }

app-website {
	.bg-gradient {
		background: linear-gradient(90deg, #0f1011, #222426) !important;
	}

	.mt-60 { margin-top: 60px;}
	.fw-bold {
		font-weight: 800 !important;
	}
	.fw-light {
		font-weight: 300 !important;
	}
	.feature-icon {
		line-height: 65px !important;
	}

	.text-soft {
		color: #d4d4d4;
	}

	// .text-justify {
	// 	text-align: justify !important;
	// }

	.partners {
		&:first-child {
			border-bottom: 0.5px solid #e0e0e0;
		}

		&:last-child {
			border-top: 0.5px solid #e0e0e0;
		}

		& > div {
			padding: 25px 40px;			

			&:first-child {
				border-left: none;
				border-right: 0.5px solid #e0e0e0;
			}

			&:last-child {
				border-right: none;
				border-left: 0.5px solid #e0e0e0;
			}

			img {
				height: 45px;
				max-height: 45px;
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
			}
		}

		footer {
			.footer-top {
				.footer-single-col h3 {
					color: #fefefe !important;
				}
			}
		}
	}

	
}

.divider {
	display: flex;
	align-items: center;
	text-align: center;
}

.divider {
	&::after, &::before {
		content: '';
		border: 1px solid $core-ui-dark-2;
		flex: 1;
	}

	&:not(:empty)::before {
		margin-right: 1em;
	}

	&:not(:empty)::after {
		margin-left: 1em;
	}
}

.custom-datepicker-dropdown .form-group .input-group .input-group-append .btn {
	background-color: #e9ecef !important;
	border: 1px solid #ced4da !important;
	line-height: 1.7 !important;
	border-radius: 0 6px 6px 0 !important;
}	

.sso-card {
	border-radius: 8px;

	.card-header {
		padding: 10.5px 22px 10.5px 22px !important;
		// padding-top: 12.5px !important;
    	// padding-bottom: 12.5px !important;

		// h5 {
		// 	line-height: 38px;
		// }

		h5, h6 {
			transform: translate(0%, 50%);
			padding-left: 5px;
		}

		.input-group-text, .form-control {
			background-color: #212325 !important;
			border: none !important;
		}
		
		span {
			padding: 5px 12px;
			padding-right: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			i {
				font-size: 20px;
			}
		}

		button {
			padding-left: 35px;
			padding-right: 35px;
		}

		.theme-form {
			input {
				background: red !important;
			}
		}
	}

	.card-body {
		padding: 20px;
	}

	
}

.ag-root-wrapper {
	border: solid 1px;
	border-color: var(--ag-border-color, red) !important;
}

.ag-header {
	border-bottom: 1px solid #384558;
}

.ag-ltr .ag-cell {
	display: flex!important;
	align-items: center;
}

.cell-vertical-align-text-left {
	display: flex!important;
	align-items: center;
  }
  
  .cell-vertical-align-text-right {
	display: flex!important;
	flex-direction: row-reverse;
	text-align: right!important;
	align-items: center;
  }

text-button-renderer {
	span {
		font-weight: 340;
	}
}

.ag-cell-value,
.ag-cell {
  overflow: visible;
}

// sidebar-custom
.sidebar-main {
	.sidebar-links > li .sidebar-link.active {

	}

	.sidebar-link.active {
        height: 50px;

		background: red;
    }
}

.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
	display: none;
}

.page-wrapper.horizontal-wrapper .page-body {
    margin-top: 80px;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links > li > a.active {
    transition: all 0.3s ease;
    background-color: rgba(115, 102, 255, 0.2);
}

// .page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active, 
// .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active  & {

	
// }

.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li {
	padding: unset;

	.sidebar-link {
		padding: 12px 25px;
		margin-bottom: 20px;
	}

	.sidebar-link.active {
		border-radius: 0;
		border-right: 3px solid #EA9706;
		background-color: #31323C;

		&:hover {
			background-color: #31323C;
		}

		font-weight: normal;

		span {
			color: #ffffff;
			font-weight: normal;
		}
	}
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper, 
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
	border-bottom: 0.5px solid #333331;
	padding: 5px 20px;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
	padding: 27px 20px;
	img {
		height: 25px;
	}
}

.page-wrapper.compact-wrapper .page-header.close_icon {
    margin-left: 65px;
    width: calc(100% - 65px);
}

.page-wrapper .page-header .header-wrapper {
    padding: 10px 30px;

	.header-logo-wrapper {
		width: 200px;

		img {
			height: 60px;
		}
	}
}

.sso-modal.dark-mode {
	.modal-header, .modal-body, .modal-footer {
		background-color: #0F1011;
		border: 1px solid #535260;
	}

	.modal-body {
		border-top: none;
		border-bottom: none;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.modal-header {
		border-bottom: none;
	}
	.modal-footer {
		border-top: none;
	}

	.theme-form input, .theme-form textarea, .theme-form select {
		color: white;
		border: 1px solid #767d84;
		background-color: #222426;
		transition: all 0.3s ease;
	}

	.theme-form label {
		color: #ffffff;
	}

	mat-select {
		.mat-form-field-label {
			text-transform: uppercase;
		}
	
		.mat-form-field-label, .mat-select-value {
			color: #ffffff;
		}
	
		.mat-select-arrow {
			color: #ea9706;
		}
	
		
	}
	
	.business-hours {
		.business-hours__left {
			width: 90px !important;
		}
	
		.day__weekday {
			color: #ffffff;
			text-transform: uppercase;
		}
	}

	.ng-select .ng-select-container, .ng-select .ng-dropdown-panel {
		border: 1px solid #767d84;
		background-color: #222426;
	}

	.ng-select.ng-select-single .ng-select-container .ng-value-container, 
	.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
		color: #ffffff;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, 
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
		color: #fff !important;
		font-weight: 500 !important;
		background-color: #ea9706 !important;
	}

	.input-group-text {
		padding: 0.5rem 0.875rem;
		background-color: #222426;
		border: 1px solid #767d84;
		border-radius: 0;
		border-top-right-radius: 0.25rem;
		border-bottom-right-radius: 0.25rem;
	}
}



.mat-form-field-appearance-legacy .mat-form-field-underline {
	background-color: #ffffff !important;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, 
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
	text-transform: uppercase;
}

actions-button-cell {
    overflow:visible; 
}

.ag-cell { 
    overflow:visible; 
}

.ag-row {
    //z-index: 0;
}

ag-row.ag-row-focus {
    z-index: 1;
}

.ag-root,
.ag-body-viewport,
.ag-body-viewport-wrapper {
    overflow: visible !important;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within, 
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), 
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, 
.ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, 
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within, 
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), 
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, 
.ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
	border: 1px solid;
    border-color: var(--ag-range-selection-border-color);
    border-style: var(--ag-range-selection-border-style);
    outline: medium none invert;
    outline: initial;
	border-color: transparent
}

.ag-cell {
	provider-logo-renderer {
		div {
			background: #ffffff;
			padding: 5px;
			border-radius: 3px;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-height: 80px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	provider-detail-renderer {
		p {
			word-break: break-all;
			white-space: normal;
			line-height: 1.28;
    		font-weight: 300;
		}

		span.seen {
			color: #747483;
    		font-weight: 300;
			font-size: smaller;

			span.date {
				color: #B3B4BF;
			}
		}
	}

	more-action-renderer {
		.dropdown-menu {
			height: auto !important;
			position: relative !important;
			transform: translate3d(0,0,10px) !important;
			border: 2px solid #2b2b2b !important;
			background-color: #ffffff !important;

			--bs-dropdown-min-width: unset;
			--bs-dropdown-padding-y: 0.275rem;

			.dropdown-item {
				color: #e29107 !important;
				font-size: 13px;
				padding: 2px 10px;
			}
		}

		.dropdown {
			margin-top: 30%;
			transform-style: preserve-3d;
			transform: translate3d(0,0,10px) !important;
		}
		 
		.dropdown-toggle {
			&.show, &::after, &:empty::after {
				display: none;
			}
		}
	}
}

.otp-input {
	
}

.otp-input[id^="otp_5"] {
	margin-right: 0 !important;
}

ngx-intl-tel-input {
	.iti {
		width: 100%;
		
		input#country-search-box {
			padding: 0.525rem 0.75rem;
			border-radius: 3px;
		}

		.iti__selected-flag {
			padding: 0 0px 0 8px !important;
		}

		.iti__country {
			padding: 10px 10px;
			outline: none;
		}

		.selected-dial-code {
			color: #ffffff;
		}

		input[type=text], input[type=tel], .form-control {
			border-color: #767d84;
    		background-color: #222426;
		}
	}
}

.auth-v2 {
	.otp-input:not(:last-child) {
		margin-right: 45px !important
	}

	input[type=text], input[type=tel], .form-control {
		border-width: 1.5px !important;
		border-color: #e29000 !important;
		background-color: #0f1011 !important;
	}
}
