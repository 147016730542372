ag-grid-angular {
	button.btn-crud {
		padding: 0.275rem 0.475rem;
    	border-width: 1.5px;

		&i{
			font-weight: 600;
		}

		&:not(:last-child) {
			margin-right: 5px;
		}

		&[data-crud="view"]::before {
			content: '\f06e';
			font-family: FontAwesome;
		}

		&[data-crud="edit"]::before {
			content: '\f044';
			font-family: FontAwesome;
		}

		&[data-crud="drop"]::before {
			content: '\f1f8';
			font-family: FontAwesome;
		}

		&[data-crud="bmu"]::before {
			content: 'Manage Users';
			letter-spacing: normal;
		}

		&[data-crud="bmp"]::before {
			content: 'Manage Providers';
			letter-spacing: normal;
		}
	}

	width: 100%;
	height: 600px;

	
}

.ag-theme-alpine-dark {
	//--ag-background-color: red;
	--ag-background-color: #0f1011;
	--ag-foreground-color: #fff;
	--ag-border-color: #0f1011;
	--ag-secondary-border-color: rgba(88, 86, 82, 0.5);
	--ag-header-background-color: #0f1011;
	--ag-odd-row-background-color: #0f1011;
	--ag-control-panel-background-color: #0f1011;
	--ag-subheader-background-color: #000;
	--ag-input-disabled-background-color: #282c2f;
	--ag-input-focus-box-shadow: 0 0 2px 0.5px rgba(255, 255, 255, 0.5), 0 0 4px 3px var(--ag-input-focus-border-color);
	--ag-card-shadow: 0 1px 20px 1px black;
	--ag-disabled-foreground-color: rgba(255, 255, 255, 0.5);
	--ag-chip-background-color: rgba(255, 255, 255, 0.07);
	--ag-input-disabled-border-color: rgba(104, 104, 110, 0.3);
	--ag-input-disabled-background-color: rgba(104, 104, 110, 0.07);
}

.no-header {
	.ag-header {
		display: none;
	}
}