/**=====================
    25. Authentication CSS Start
==========================**/

.txt-twitter {
  	color: #6fa2d8 !important;
}

.txt-linkedin {
  	color: #0077B5 !important; 
}

.txt-fb {
  	color: #50598e !important;
}

.login-card {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	background: url('../../images/login/login_bg.jpg');
	background-position: center;
	padding: 30px 0;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.8);
	}

	.logo {
		display: block;
		margin-bottom: 30px;
		text-align: center;
	}

	.btn-showcase {
		.btn {
			line-height: 1;
			padding: 10px 15px;
			margin: 0;

			&+.btn {
				margin-left: 5px;
			}

			svg {
				height: 16px;
				vertical-align: bottom;
			}
		}
	}

  	.login-main {
		width: 450px;
		padding: 40px 35px;
		border-radius: 5px;
		box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
		margin: 0 auto;
		background-color: $core-ui-dark;

		.theme-form {
			h4 {
				margin-bottom: 5px;
			}

			label {
				color: $white;
				font-size: 15px;
				letter-spacing: 0.4px;
				font-weight: 300;
			}

			.checkbox label::before {
				background-color: transparent;
				border: 1px solid #dfdfdf;
			}

			.checkbox label {
				font-weight: normal;
			}

			.terms-mail {
				margin-left: 15px;

				.checkbox label {
					font-size: 12.5px;
					font-weight: 400;
					float: left;
				}
			}

			.or {
				position: relative;
				&:before {
					content: '';
					position: absolute;
					width: 65%;
					height: 2px;
					background-color: #f3f3ff;
					top: 9px;
					z-index: 0;
					right: 0;
				}
			}

			input {
				color: white;
				//font-size: 16px;
				border: 1px solid lighten($core-ui-dark-2, 35%);
				background-color: $core-ui-dark-2;
				transition: all 0.3s ease;

				&::-webkit-input-placeholder {
					color: $light-text;
				}

				&:hover,
				&:focus {
					border: 1px solid lighten($core-ui-dark-2, 35%);
					box-shadow: none !important;
					transition: all 0.3s ease;
				}

				
				.ng-invalid, .has-feedback {
					border-color: #dc3545 !important;
				}
			}

			button {
				color: $white;
				i {
					margin-right: .5rem;
					font-size: 1.5em;
				}
			}

			button.submit {
				float: right;
				padding: 0.475rem 1.475rem;
			}

			h5 {
				color: $white;
				font-size: 1.190rem;
				font-weight: 400;
			}

			p {
				margin-bottom: 25px;
				font-size: 14px;
				color: $dark-gray;
			}

			.form-group {
				margin-bottom: 8px;
				position: relative;
			}

			.link {
				position: absolute;
				top: 2px;
				right: 0;
			}

			em {
				font-size: 12px;
			}
		}
  	}
}

.show-hide {
	position: absolute;
	top: 56px;
	right: 15px;
	transform: translateY(-50%);

  	span {
		cursor: pointer;
		font-size: 13px;
		color: var(--theme-default);

		&.show {
			&:before {
				content: 'show';
			}
		}

		&:before {
			content: 'hide';
		}
	}
}

.needs-validation {
	.invalid-feedback {
		font-size: 80%;
	}

	.show-hide {
		right: 30px;
	}

	.invalid-tooltip {
		top: 10px;
		right: 10px;
		left: unset;
	}
}

/**=====================
    25. Authentication CSS Ends
==========================**/