.modal {
    background: rgba(0, 0, 0, .45);
	--bs-modal-inner-border-radius: calc(0.5rem - 3px);
}

.btn-close {
	border: 0;
	border-radius: 0.375rem;
	opacity: 1;
}

.modal-dialog {

	.modal-header {
		padding: 1rem 1.5rem 1rem 1.5rem;
		background: $core-ui-dark-3;

		.modal-title {
			color: white;
			line-height: 1.2;
			font-size: 1.375rem;
			font-weight: 300;
			margin-bottom: 0;
		}
	}

	.modal-body {
		padding: 1.45rem;
	}

	.modal-footer {
		width: 100%;
	}
}